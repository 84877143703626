<template>
  <el-menu
    class="sidebar-menu"
    @mouseenter.native="is_show = true"
    @mouseleave.native="is_show = false"
    :collapse="!is_show"
    :unique-opened="true"
    :router="true"
    text-color="#000"
    active-text-color="#409EFF">
    <el-submenu
      index="1"
      tag="a"
      v-if="role.isSuperAdmin || role.isAdmin || role.isSupport">
      <template slot="title">
        <img src="./icons/sms-report.png" class="menu-icon" alt="" />
        Tests
      </template>
      <el-menu-item index="/sms-report" :route="{ name: 'sms-report' }"
        ><router-link :to="{ name: 'sms-report' }"
          >SMS reports</router-link
        ></el-menu-item
      >
      <el-menu-item index="/proxy-tests" :route="{ name: 'proxy-tests' }"
        ><router-link :to="{ name: 'proxy-tests' }"
          >Proxy reports</router-link
        ></el-menu-item
      >
      <el-menu-item
        index="/custom-number-reports"
        :route="{ name: 'custom-number-reports' }"
        ><router-link :to="{ name: 'custom-number-reports' }"
          >Custom number reports</router-link
        ></el-menu-item
      >
      <el-menu-item index="/mo-reports" :route="{ name: 'mo-reports' }"
        ><router-link :to="{ name: 'mo-reports' }"
          >MO reports</router-link
        ></el-menu-item
      >
      <el-menu-item index="/auto-reports" :route="{ name: 'auto-reports' }"
        ><router-link :to="{ name: 'auto-reports' }"
          >Auto reports</router-link
        ></el-menu-item
      >
    </el-submenu>
    <el-submenu index="2">
      <template slot="title">
        <img src="./icons/user-list.png" class="menu-icon" alt="" />
        Users
      </template>
      <el-menu-item index="/user-list" :route="{ name: 'user-list' }">
        <router-link :to="{ name: 'user-list' }">User list</router-link>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="3">
      <template slot="title">
        <img src="./icons/payment-plans.png" class="menu-icon" alt="" />
        Billing
      </template>
      <el-menu-item index="/payment-plans" :route="{ name: 'payment-plans' }">
        <router-link :to="{ name: 'payment-plans' }">Payment plans</router-link>
      </el-menu-item>
      <el-menu-item
        v-if="role.isSuperAdmin || role.isAdmin || role.isFinancial"
        index="/invoices"
        :route="{ name: 'invoices' }">
        <router-link :to="{ name: 'invoices' }">Invoices</router-link>
      </el-menu-item>
    </el-submenu>
    <el-submenu
      index="4"
      v-if="role.isSuperAdmin || role.isAdmin || role.isSupport">
      <template slot="title">
        <img src="./icons/vendors.svg" class="menu-icon" alt="" />
        Settings
      </template>
      <el-menu-item index="/binds" :route="{ name: 'binds' }">
        <router-link :to="{ name: 'binds' }">Binds</router-link>
      </el-menu-item>
      <el-menu-item
        index="/api-constructors"
        :route="{ name: 'api-constructors' }">
        <router-link :to="{ name: 'api-constructors' }"
          >Api constructors</router-link
        >
      </el-menu-item>
      <el-menu-item index="/vendors" :route="{ name: 'vendors' }">
        <router-link :to="{ name: 'vendors' }">Vendors</router-link>
      </el-menu-item>
      <el-menu-item index="/my-profile" :route="{ name: 'my-profile' }">
        <router-link :to="{ name: 'my-profile' }">My profile</router-link>
      </el-menu-item>
      <el-menu-item
        v-if="role.isSuperAdmin"
        index="/admin-list"
        :route="{ name: 'admin-list' }"
        ><router-link :to="{ name: 'admin-list' }"
          >Admin list
        </router-link></el-menu-item
      >
      <el-menu-item index="/provider-list" :route="{ name: 'provider-list' }"
        ><router-link :to="{ name: 'provider-list' }"
          >Providers
        </router-link></el-menu-item
      >
      <el-menu-item index="/mo-networks" :route="{ name: 'mo-networks' }"
        ><router-link :to="{ name: 'mo-networks' }"
          >MO networks
        </router-link></el-menu-item
      >
      <el-menu-item index="/mo-providers" :route="{ name: 'mo-numbers' }"
        ><router-link :to="{ name: 'mo-numbers' }"
          >MO numbers
        </router-link></el-menu-item
      >
      <el-menu-item index="/nodes-list" :route="{ name: 'nodes-list' }"
        ><router-link :to="{ name: 'nodes-list' }"
          >Nodes list
        </router-link></el-menu-item
      >
      <el-menu-item
        v-if="role.isSuperAdmin || role.isAdmin"
        index="/text-templates"
        :route="{ name: 'text-templates' }"
        ><router-link :to="{ name: 'text-templates' }"
          >Text templates</router-link
        ></el-menu-item
      >
      <el-menu-item
        v-if="role.isSuperAdmin || role.isAdmin || role.isSupport"
        index="/sms-templates"
        :route="{ name: 'sms-templates' }"
        ><router-link :to="{ name: 'sms-templates' }"
          >SMS templates</router-link
        ></el-menu-item
      >
      <el-menu-item
        v-if="role.isSuperAdmin || role.isAdmin || role.isCommercial"
        index="/notifications"
        :route="{ name: 'notifications' }"
        ><router-link :to="{ name: 'notifications' }"
          >Notifications</router-link
        ></el-menu-item
      >
    </el-submenu>
    <el-submenu index="5" tag="a">
      <template slot="title">
        <img src="./icons/analytics.png" class="menu-icon" alt="" />
        Analytics
      </template>
      <el-menu-item
        index="/analytics/tests"
        :route="{ name: 'analytics-tests' }"
        ><router-link :to="{ name: 'analytics-tests' }"
          >Tests</router-link
        ></el-menu-item
      >
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      is_show: false,
    };
  },
  computed: {
    ...mapGetters({
      role: "getRole",
    }),
  },
};
</script>
